<template>
  <div>
    <h1>手動時計</h1>
    <p>
      マス目をタップすると色が変わって、みんなの画面に反映させれます。<br>
      今の時刻に合わせたり、今日寝たい時間に合わせたり、好きな模様を描いたりして楽しんでください🥺<br>
      もしいい感じにできたらスクショをポストして共有してください。 
      <a href="https://twitter.com/intent/tweet?button_hashtag=手動時計&ref_src=twsrc%5Etfw" class="twitter-hashtag-button" data-show-count="false">Tweet #手動時計</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      
    </p>
    <div v-if="user">
      <p>ログイン済です</p>
      <button @click="logout">ログアウト</button>
    </div>
    <div v-else>
      <button @click="login('google')">Googleでログイン</button>
      <button @click="login('twitter')">X（Twitter）でログイン</button>
      <br>
    </div>
    <div>
      <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="row">
        <div
          v-for="(cell, colIndex) in row"
          :key="colIndex"
          :class="['cell', cell === 1 ? 'black' : 'white']"
          @click="throttledToggleCell(rowIndex, colIndex)"
        ></div>
      </div>
      <p class="connection-count">今 {{ connectionCount }}人います</p>
      <p class="tap-count" v-if="user || guestTapCount > 0">
        時刻調整に{{ userTapCount }}タップ貢献しました
      </p>
     
      <div v-if="tapRankings.length > 0" class="ranking">
        <h3>ランキング</h3>
        <ul>
          <li v-for="(rank, index) in tapRankings" :key="index">
            {{ index + 1 }}位: {{ rank.tapCount }} 回
          </li>
        </ul>
      </div>
      <div>
      <a class="twitter-timeline" href="https://twitter.com/crowdclocknet?ref_src=twsrc%5Etfw">お知らせなど（X）</a>
      <h3>宣伝（アマゾン）</h3>
      <a>時計いかがですか？</a>
      <a class="amazon" href="https://amzn.to/4hpoazM">セイコーの電波時計</a><br>
      <a class="amazon" href="https://amzn.to/3NJVBQ4">カラー液晶で喋る時計？</a>
      </div>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signOut,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  set,
  onDisconnect,
  runTransaction,
} from "firebase/database";
import { throttle } from "lodash";

// Firebaseの設定
const firebaseConfig = {
  apiKey: "AIzaSyDNZxmAEOv0136-G-HTy2iznJMPc_vn9YU",
  authDomain: "crowdclock.net",
  databaseURL: "https://crowdclock1-default-rtdb.firebaseio.com/",
  projectId: "crowdclock1",
  storageBucket: "crowdclock1.appspot.com",
  messagingSenderId: "221782160075",
  appId: "1:221782160075:web:4b48c3278a89416664214e",
  measurementId: "G-YW0Y7L67EW",
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const dbRef = ref(db, "grid");
const connectionsRef = ref(db, "connections");
const connectedRef = ref(db, ".info/connected");
const tapCountRef = ref(db, "tapCounts");

export default {
  name: "RealtimeGrid",
  data() {
    return {
      grid: Array(5)
        .fill()
        .map(() => Array(17).fill(0)), // 初期状態は白（0）
      connectionCount: 0,
      user: null,
      guestTapCount: 0, // 未ログインユーザーのタップ数
      userTapCount: 0,
      tapRankings: [],
      rankingsInterval: null, // ランキング更新の間隔
    };
  },
  created() {
    // リダイレクト結果の取得
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          this.user = result.user;
          this.fetchUserTapCount(result.user.uid);
        }
      })
      .catch((error) => {
        console.error("リダイレクト結果の取得中にエラーが発生しました:", error);
      });

    // ログイン状態の監視
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.fetchUserTapCount(user.uid);
        this.setupThrottledToggleCell(300); // ログインユーザーのスロットル間隔を1秒に設定
      } else {
        this.user = null;
        this.userTapCount = 0;
        this.setupThrottledToggleCell(700); // ゲストユーザーのスロットル間隔を3秒に設定
      }
    });

    this.setupRealtimeDatabase();
    this.fetchTapRankings();
  },
  mounted() {
    // ランキングを1分おきに更新
    this.rankingsInterval = setInterval(this.fetchTapRankings, 60000);
  },
  beforeUnmount() {
    clearInterval(this.rankingsInterval);
  },
  methods: {
    login(providerName) {
      let provider;

      if (providerName === "google") {
        provider = new GoogleAuthProvider();
      } else if (providerName === "twitter") {
        provider = new TwitterAuthProvider();
      }

      provider.setCustomParameters({ prompt: "select_account" }); // 常にアカウント選択を表示
      signInWithRedirect(auth, provider).catch((error) => {
        console.error("ログイン中にエラーが発生しました:", error);
        alert("ログイン中にエラーが発生しました。再度お試しください。");
      });
    },
    logout() {
      signOut(auth)
        .then(() => {
          console.log("ログアウト成功");
        })
        .catch((error) => {
          console.error("ログアウト中にエラーが発生しました:", error);
          alert("ログアウト中にエラーが発生しました。再度お試しください。");
        });
    },
    setupRealtimeDatabase() {
      // 初期データの取得
      onValue(
        dbRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            this.grid = data;
          }
        },
        { onlyOnce: true }
      );

      // グリッドデータのリアルタイム更新
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.grid = data;
        }
      });

      // 同時接続数の監視
      onValue(connectionsRef, (snapshot) => {
        const connections = snapshot.val();
        this.connectionCount = connections ? Object.keys(connections).length : 0;
      });

      // 新しい接続を追加
      onValue(connectedRef, (snapshot) => {
        if (snapshot.val() === true) {
          const connection = ref(db, `connections/${Date.now()}`);
          set(connection, true);
          onDisconnect(connection).remove();
        }
      });
    },
    setupThrottledToggleCell(interval = 3000) {
      this.throttledToggleCell = throttle((row, col) => {
        if (!this.user && this.guestTapCount >= 10) {
          alert(
            "リロードするか、ログインしてください。ログインすると反映が早くなり、上位はランキングに載ります。(簡易的な荒らし対策です。ご了承ください)"
          );
          return;
        }

        const cellRef = ref(db, `grid/${row}/${col}`);
        runTransaction(cellRef, (currentValue) => {
          return currentValue === 0 ? 1 : 0;
        }).catch((error) => {
          console.error("セルの更新中にエラーが発生しました:", error);
        });

        // タップ回数の更新
        if (!this.user) {
          this.guestTapCount++;
        } else {
          this.userTapCount++;
          const userTapRef = ref(db, `tapCounts/${this.user.uid}`);
          set(userTapRef, this.userTapCount).catch((error) => {
            console.error("タップ回数の更新中にエラーが発生しました:", error);
          });
        }
      }, interval); // スロットル間隔を設定
    },
    toggleCell(row, col) {
      if (this.throttledToggleCell) {
        this.throttledToggleCell(row, col);
      }
    },
    fetchUserTapCount(userId) {
      const userTapRef = ref(db, `tapCounts/${userId}`);
      onValue(userTapRef, (snapshot) => {
        this.userTapCount = snapshot.val() || 0;
      });
    },
    fetchTapRankings() {
      onValue(tapCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const rankings = Object.entries(data).map(([uid, tapCount]) => {
            return { uid, tapCount };
          });
          rankings.sort((a, b) => b.tapCount - a.tapCount);
          this.tapRankings = rankings.slice(0, 5); // 上位5名を表示
        }
      });
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: center;
}
.cell {
  width: calc(min((100vw - 34px) / 17, (100vh - 34px) / 5));
  height: calc(min((100vw - 34px) / 17, (100vh - 34px) / 5));
  margin: 1px;
  cursor: pointer;
  border: 1px solid #ddd;
}
.white {
  background-color: white;
}
.black {
  background-color: black;
}
.connection-count {
  font-size: 12px;
  color: gray;
  margin-top: 10px;
}
.tap-count {
  font-size: 14px;
  margin-top: 10px;
}
.ranking {
  margin-top: 20px;
}
</style>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
